<script lang="ts">
    import {mobileMenuOpen as opened} from "@stores/mobile-menu-open";

    let className = "";
    export {className as class};
</script>

<nav class={className} class:mobile-menu={true} class:opened={$opened} class:closed={!$opened}>
    <div class="links">
        <slot name="main" />
    </div>
    <div class="bottom-links">
        <slot name="bottom" />
    </div>
</nav>

<style>
    .mobile-menu {
        backdrop-filter: blur(0.5rem);
        background: linear-gradient(
            0deg,
            color-mix(in srgb, var(--color-secondary-900) 95%, transparent) 0%,
            color-mix(in srgb, var(--color-secondary-950) 95%, transparent) 100%
        );
        bottom: 0;
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        left: 0;
        padding-top: var(--size-24);
        padding-left: var(--size-6);
        padding-right: var(--size-6);
        padding-bottom: var(--size-6);
        right: 0;
        top: 0;
        transition: all 100ms ease-out;
        z-index: var(--z-index-backdrop);
    }
    .links {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    div.bottom-links {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        row-gap: var(--size-8);
    }
    .opened {
        opacity: 1;
    }
    .closed {
        opacity: 0;
        pointer-events: none;
    }

    /** Workaround: (--from-desktop) does not work here for some reason... */
    @media (min-width: 768px) {
        .mobile-menu {
            display: none;
        }
    }
</style>
