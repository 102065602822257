<div class="root" />

<style>
    .root {
        width: 1em;
        height: 1em;
        border: 0.125em solid var(--color-shade-50);
        border-top: 0.125em solid transparent;
        border-radius: 50%;
        animation: spin 0.5s linear 0s infinite;
    }

    @keyframes spin {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(359deg);
        }
    }
</style>
