<script lang="ts">
    import {fade} from "svelte/transition";
    export let visible = false;
</script>

{#if visible}
    <div transition:fade />
{/if}

<style>
    div {
        background: rgba(0, 0, 0, 0.8);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
</style>
