<script lang="ts">
    import {combineClasses} from "@utils/component-utils";

    let className = "";
    export {className as class};
    export let id: string | undefined = undefined;
    export let name: string | undefined = undefined;
    export let value: string | undefined = undefined;
</script>

<div class={combineClasses(["root", className])}>
    <input type="hidden" {id} {name} {value} />
</div>

<style>
    .root {
        display: none;
    }
</style>
